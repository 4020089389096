@charset "utf-8";

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials


// overwrite the linkedin icon to be black
.fa-linkedin {
  color: black !important;
}

.page__footer footer{
  padding-bottom: 30px;
  margin-top: 20px;
}

// changing sidebar opacity, because it looks dumb
.sidebar {
  @include clearfix();
  // @include breakpoint(max-width $large) {
  //   /* fix z-index order of follow links */
  //   position: relative;
  //   z-index: 10;
  //   -webkit-transform: translate3d(0, 0, 0);
  //   transform: translate3d(0, 0, 0);
  // }

  @include breakpoint($large) {
    float: inline-start;
    width: calc(#{$right-sidebar-width-narrow} - 1em);
    opacity: 1;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    &.sticky {
      overflow-y: auto;
      /* calculate height of nav list
          viewport height - nav height - masthead x-padding
      */
      max-height: calc(100vh - #{$nav-height} - 2em);
    }
  }

  @include breakpoint($x-large) {
    width: calc(#{$right-sidebar-width} - 1em);
  }

  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    font-family: $sans-serif-narrow;
  }

  p,
  li {
    font-family: $sans-serif;
    font-size: $type-size-6;
    line-height: 1.5;
  }

  img {
    width: 100%;

    &.emoji {
      width: 20px;
      height: 20px;
    }
  }
}
